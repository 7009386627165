@import '../../../../styles/customMediaQueries.css';

.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  height: 1200px;
  min-height: 80vh;
  max-height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchContainer {
  width: 100%;
  margin: auto;
  margin-top: 60px;
}

.root div[class*='SectionContainer_sectionContent'] {
  width: 100%;
}

.root header[class*='SectionBuilder_sectionDetails'] {
  max-width: 1370px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleWrapper {
  display: flex;
  flex-direction: column;

  @media (--viewportLargeWithPaddings) {
    flex-direction: row;
  }
}

.titleImgs {
  display: flex;
  margin-bottom: 15px;
  justify-content: center;

  @media (--viewportLargeWithPaddings) {
    margin-bottom: 0;
    justify-content: flex-start;
  }
}

.urbansquirrelLogo {
  height: 35px;
  margin-right: 15px;

  @media (--viewportMedium) {
    height: 42px;  
  }

  @media (--viewportLargeWithPaddings) {
    height: 52px;
  }

  @media (--viewportMLarge) {
    height: 70px;
  }
}

.titleWrapper img[class*='Logo_logo'] {
  max-height: 70px;
  max-width: unset;
}

.squirrelIcon {
  width: 30px;
  height: 30px;
  margin-right: 10px;

  @media (--viewportMedium) {
    width: 38px;
    height: 38px;
  }

  @media (--viewportLargeWithPaddings) {
    width: 45px;
    height: 43px;
  }

  @media (--viewportMLarge) {
    width: 56px;
    height: 55px;
  }
}

.titleSeparator {
  font-size: 35px;
  font-weight: var(--fontWeightRegular);
  line-height: 35px;
  color: var(--marketplaceColor);
  margin-right: 15px;
  display: none;

  @media (--viewportLargeWithPaddings) {
    display: inline;
    font-size: 45px;
    line-height: 52px;
  }

  @media (--viewportMLarge) {
    font-size: 58px;
    line-height: 62px;
  }
}

.titleWrapper h1 {
  font-size: 45px;
  line-height: 52px;
  font-weight: var(--fontWeightRegular);
  color: var(--marketplaceColor);
  text-align: center;

  @media (--viewportLargeWithPaddings) {
    text-align: left;
    font-size: 45px;
    line-height: 52px;
  }

  @media (--viewportMLarge) {
    font-size: 58px;
    line-height: 62px;
  }
}

.root header[class*='SectionBuilder_sectionDetails'] p {
  font-size: 30px;
  line-height: 48px;
  margin-top: 70px;
  color: var(--marketplaceColor);
  text-align: center;

  @media (--viewportLargeWithPaddings) {
    font-size: 40px;
    line-height: 48px;
    margin-top: 60px;
  }
}
